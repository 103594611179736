import { DragEvent, ReactNode, useCallback, useRef, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { Loader } from '@/core/components/component-library/Icon/loader';
import useFilesValidator from '@/core/hooks/useFilesValidator';
import { toast } from '@/core/libs/toast';
import { cn } from '@/core/libs/utils';
import uploadAnimation from '@/modules/matchmaking/assets/upload-animation.lottie.json';
import { AnalyzedPart } from '@/modules/matchmaking/types';

export interface DropZoneProps {
  children: ReactNode;
  dropContent?: ReactNode;
  allowedExtensions?: string[];
  sizeLimitMB?: number;
  existingFiles?: (File | AnalyzedPart)[];
  checkDuplicates?: boolean;
  className?: string;
  dropAreaClassName?: string;
  variant?: 'fullHeight' | 'contained';
  mode?: 'light' | 'dark';
  loading?: boolean;
  onDrop(files: (File | null)[]): void;
}

enum DraggingMode {
  NONE = 'none',
  SINGLE_FILE = 'single',
  MULTIPLE_FILES = 'multiple',
}

export function DropZone({
  className,
  dropAreaClassName,
  children,
  dropContent,
  sizeLimitMB = 150,
  existingFiles = [],
  checkDuplicates = false,
  allowedExtensions = [],
  variant = 'fullHeight',
  mode = 'light',
  loading,
  onDrop,
}: DropZoneProps): JSX.Element {
  const { $t } = useIntl();
  const dropAreaRef = useRef<HTMLDivElement>(null);
  const [draggingMode, setDraggingMode] = useState<DraggingMode>(
    DraggingMode.NONE,
  );
  const { validateFiles } = useFilesValidator({
    allowedExtensions,
    existingFiles,
    sizeLimitMB,
    checkDuplicates,
  });

  const handleDragEnter = useCallback(
    (event: DragEvent): void => {
      if (draggingMode === DraggingMode.NONE) {
        const fileLength = [...event.dataTransfer.items].filter(
          (file) => file.kind === 'file',
        ).length;
        if (fileLength) {
          setDraggingMode(
            fileLength > 1
              ? DraggingMode.MULTIPLE_FILES
              : DraggingMode.SINGLE_FILE,
          );
        }
      }
    },
    [draggingMode],
  );

  const handleDrop = useCallback(
    (files: File[]): void => {
      if (loading) {
        toast({
          appearance: 'info',
          message: $t({ id: 'General.dropzone.cannotDropWhileLoading' }),
        });
        setDraggingMode(DraggingMode.NONE);
        return;
      }
      const { acceptedFiles } = validateFiles(files);
      if (acceptedFiles.length) onDrop(acceptedFiles);
      setDraggingMode(DraggingMode.NONE);
    },
    [loading, validateFiles, onDrop, $t],
  );

  const handleDragLeave = useCallback(
    (event: DragEvent) => {
      if (
        draggingMode !== DraggingMode.NONE &&
        event.target === dropAreaRef.current
      ) {
        setDraggingMode(DraggingMode.NONE);
      }
    },
    [draggingMode],
  );

  const renderSizeAndFileLimit = useCallback((): JSX.Element => {
    const extensions = [...allowedExtensions].map((value) =>
      value.toLocaleUpperCase(),
    );
    const last = extensions.pop();
    let text = extensions.length ? `${extensions.join(', ')} & ${last}` : last;

    if (sizeLimitMB && text) {
      text = $t(
        {
          id: 'General.dropzone.fileFormatAndSizeLimit',
        },
        { sizeLimit: sizeLimitMB, extensionList: text },
      );
    } else if (sizeLimitMB) {
      text = $t(
        {
          id: 'General.dropzone.fileSizeLimit',
        },
        { sizeLimit: sizeLimitMB },
      );
    }

    return text ? <div>{text}</div> : <></>;
  }, [allowedExtensions, sizeLimitMB, $t]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick, ...rootProps } = getRootProps();

  return (
    <div
      onDragOver={(event): void => event.preventDefault()}
      onClick={(event): void => {
        if (draggingMode !== DraggingMode.NONE) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
      className={classNames(
        'relative h-full w-full',
        variant === 'fullHeight' ? 'h-[100dvh]' : 'h-fit w-fit',
        draggingMode !== DraggingMode.NONE ? 'overflow-hidden' : '',
        className,
      )}
      {...rootProps}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input {...getInputProps()} />
      {children}
      {draggingMode !== DraggingMode.NONE && (
        <div
          ref={dropAreaRef}
          className={cn(
            'absolute left-0 top-0 z-50 w-full animate-fade-in bg-[rgba(255,255,255,0.8)] [&>*]:pointer-events-none',
            variant === 'fullHeight' ? 'h-[100dvh] p-10' : 'h-full w-full p-0',
            dropAreaClassName,
          )}
        >
          <div className="flex size-full flex-col items-center justify-center rounded-2xl border border-dashed p-2">
            {loading ? (
              <Loader size="lg" />
            ) : (
              dropContent || (
                <>
                  <Player
                    src={uploadAnimation}
                    autoplay
                    loop
                    style={{
                      height: variant === 'fullHeight' ? 190 : 86,
                    }}
                  />
                  <div
                    className={cn(
                      'text-3xl font-semibold',
                      mode === 'light' ? 'text-black' : 'text-white',
                      variant === 'fullHeight' ? 'text-3xl' : 'text-base',
                    )}
                  >
                    {$t({
                      id: 'General.dropzone.dropYourFilesHere',
                    })}
                  </div>
                  {variant === 'fullHeight' && (
                    <div className="flex flex-col items-center text-sm text-neutral-800 md:flex-row">
                      {renderSizeAndFileLimit()}
                      {<span className="hidden p-1 md:block">|</span>}
                      {$t({
                        id: 'General.dropzone.uploadsAreConfidential',
                      })}
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
