/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Company } from './Company';
import type { File } from './File';
import type { Project } from './Project';

export type ProjectInvitation = {
  id: number;
  created_at: string;
  updated_at: string;
  /**
   * The token that will be send in the email for retrieving the invitation
   */
  token: string;
  /**
   * The url of the manufacturer that we got from the webBase
   */
  manufacturerUrl: string | null;
  status: ProjectInvitation.status;
  origin: ProjectInvitation.origin;
  isBookmarked: boolean;
  /**
   * Freemium users has 1 unlocked RFQ/month
   */
  isLocked: boolean;
  projectId: number;
  project: Project;
  manufacturerId: number;
  ndaSignatureAt: string | null;
  lastVisitAt: string | null;
  /**
   * Is loaded only for buyer
   */
  manufacturer: Company | null;
  ndaSignedFile: File | null;
  ndaRequestStatus: ProjectInvitation.ndaRequestStatus | null;
  lastReminderSentAt: string | null;
  remindersSentCount: number | null;
  projectViewedAt: string | null;
  statusAtLastReminder: ProjectInvitation.statusAtLastReminder | null;
  timeOutAt?: string | null;
};

export namespace ProjectInvitation {
  export enum status {
    PENDING = 'PENDING',
    VIEWED = 'VIEWED',
    INTERESTED = 'INTERESTED',
    REJECTED = 'REJECTED',
    TIMED_OUT = 'TIMED_OUT',
  }

  export enum origin {
    INVITED = 'INVITED',
    BOOKMARKED = 'BOOKMARKED',
  }

  export enum ndaRequestStatus {
    PENDING = 'PENDING',
    GRANTED = 'GRANTED',
    DENIED = 'DENIED',
    REVOKED = 'REVOKED',
  }

  export enum statusAtLastReminder {
    PENDING = 'PENDING',
    VIEWED = 'VIEWED',
    INTERESTED = 'INTERESTED',
    REJECTED = 'REJECTED',
    TIMED_OUT = 'TIMED_OUT',
  }
}
