import useSupabase from '@/core/hooks/useSupabase';
import { errorHandler } from '@/core/libs/error-handler';

export type UseResetPasswordForEmail = (email: string) => Promise<boolean>;

export default function useResetPasswordForEmail(): UseResetPasswordForEmail {
  const supabase = useSupabase();
  const resetPasswordForEmail = async (email: string): Promise<boolean> => {
    const redirectTo = `${window.location.origin}/reset-password?utm_source=system-emails&utm_medium=email&utm_campaign=account-reset-password&utm_content=reset-password`;
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo,
    });
    if (error) {
      errorHandler.capture(error, { userErrorMessage: error.message });
      throw error;
    }
    return true;
  };
  return resetPasswordForEmail;
}
