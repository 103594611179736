import { useQuery } from '@tanstack/react-query';

import {
  AnalyticsService,
  InvitationsService,
  UsersService,
} from '@/generated/api';

export function useInvitationsThisMonth(isLoggedIn: boolean) {
  return useQuery({
    queryKey: ['invitationsThisMonth'],
    enabled: isLoggedIn,
    queryFn: () =>
      InvitationsService.invitationsControllerCountFreeInvitationsThisMonth(),
  });
}

export function useGetManufacturerAnalytics() {
  return useQuery({
    queryKey: ['getManufacturerAnalytics'],
    queryFn: () => AnalyticsService.analyticsControllerUserAnalytics(),
  });
}

export function useUserNotificationSettings() {
  return useQuery({
    queryKey: ['userNotificationSettings'],
    queryFn: () => UsersService.userControllerGetEmailSettings(),
  });
}

export function usePublicNotificationSettings(email: string, token: string) {
  return useQuery({
    queryKey: ['publicNotificationSettings', email, token],
    queryFn: () =>
      UsersService.userControllerGetUnsignedUserSettings(email, token),
  });
}
