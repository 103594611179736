/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { EmailSettingsRequest } from '../models/EmailSettingsRequest';
import type { EmailUnsubscription } from '../models/EmailUnsubscription';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { User } from '../models/User';

export class UsersService {
  /**
   * @param email
   * @returns any User Id.
   * @throws ApiError
   */
  public static userControllerUserExists(email: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/exists',
      query: {
        email: email,
      },
      errors: {
        404: `User not found.`,
      },
    });
  }

  /**
   * @returns User
   * @throws ApiError
   */
  public static userControllerGetMe(): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me',
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static userControllerUpdateUser(
    id: number,
    requestBody: UpdateUserRequest,
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EmailUnsubscription
   * @throws ApiError
   */
  public static userControllerGetEmailSettings(): CancelablePromise<EmailUnsubscription> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/settings/emails',
    });
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static userControllerUpdateEmailSettings(
    requestBody: EmailSettingsRequest,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/settings/emails',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param email
   * @param token
   * @returns EmailUnsubscription
   * @throws ApiError
   */
  public static userControllerGetUnsignedUserSettings(
    email: string,
    token: string,
  ): CancelablePromise<EmailUnsubscription> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/email-settings',
      query: {
        email: email,
        token: token,
      },
    });
  }

  /**
   * @param email
   * @param token
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static userControllerUnsubscribeUnsignedUser(
    email: string,
    token: string,
    requestBody: EmailSettingsRequest,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/email-settings',
      query: {
        email: email,
        token: token,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
