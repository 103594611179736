import { useQuery } from '@tanstack/react-query';

import { errorHandler } from '@/core/libs/error-handler';
import { InvitationsService, ProjectsService } from '@/generated/api';

export enum RFQNavigationType {
  TOKEN,
  LINK,
}

export function useRFQBuyerDetails(projectId: number) {
  return useQuery({
    queryKey: ['getRFQBuyerDetails', { projectId }],
    queryFn: () => InvitationsService.invitationsControllerFindBuyer(projectId),
    enabled: !!projectId,
  });
}

export function useRFQDetails({
  projectIdOrToken,
  type,
  isEdit = false,
}: {
  projectIdOrToken?: string;
  type: RFQNavigationType;
  isEdit?: boolean;
}) {
  return useQuery({
    queryKey: ['getRFQDetails', { projectIdOrToken, type, isEdit }],
    queryFn: async () => {
      if (!projectIdOrToken) {
        return { project: undefined, invitation: undefined };
      }

      try {
        if (type === RFQNavigationType.LINK) {
          const project = await ProjectsService.projectsControllerFind(
            parseInt(projectIdOrToken),
            isEdit ? isEdit : undefined,
          );

          const invitation = await InvitationsService.invitationsControllerFind(
            parseInt(projectIdOrToken),
          ).catch(() => {
            return undefined;
          });
          return { project, invitation };
        } else if (type === RFQNavigationType.TOKEN) {
          const invitation =
            await InvitationsService.invitationsControllerFindFromToken(
              projectIdOrToken,
            );
          const project = invitation?.project;
          return { project, invitation };
        }
        return { project: undefined, invitation: undefined };
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
        return { project: undefined, invitation: undefined };
      }
    },
    enabled: !!projectIdOrToken,
    refetchOnWindowFocus: false,
  });
}

export function useRFQInvitation(projectIdOrToken: number) {
  return useQuery({
    queryKey: ['getRFQInvitation', { projectIdOrToken }],
    queryFn: () =>
      InvitationsService.invitationsControllerFind(projectIdOrToken),
    enabled: !!projectIdOrToken,
  });
}
